import { Interview } from "@/interfaces/responses/interviews/interviews";
import {
  CAMERA_RECORDING_PERMISSION,
  CAMERA_RECORDING_SUPPORTED,
  CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT,
  CANDIDATE_INTERVIEW_DETAILS,
  CANDIDATE_INTERVIEW_HISTORY,
  CANDIDATE_INTERVIEW_ID,
  CANDIDATE_INTERVIEW_LOADING,
  CANDIDATE_INTERVIEW_STOP
} from "./constants";
import { CandidateInterview } from "./interfaces";
import { InterviewChat } from "@/interfaces/candidate/candidate_interview";

export default {
  [CANDIDATE_INTERVIEW_LOADING]: (state: CandidateInterview): boolean =>
    state.loading,
  [CANDIDATE_INTERVIEW_ID]: (state: CandidateInterview): number | null =>
    state.interview_id,
  [CANDIDATE_INTERVIEW_DETAILS]: (
    state: CandidateInterview
  ): Interview.Response | null => state.interview_details,
  [CAMERA_RECORDING_SUPPORTED]: (state: CandidateInterview): boolean =>
    state.camera_recording_supported,
  [CAMERA_RECORDING_PERMISSION]: (state: CandidateInterview): boolean =>
    state.camera_recording_permission,
  [CANDIDATE_INTERVIEW_HISTORY]: (state: CandidateInterview): InterviewChat[] =>
    state.interview_history,
  [CANDIDATE_INTERVIEW_ANSWER_TRANSCRIPT]: (
    state: CandidateInterview
  ): string => state.answer_transcript,
  [CANDIDATE_INTERVIEW_STOP]: (state: CandidateInterview): boolean =>
    state.interview_stop
};
