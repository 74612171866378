import {
  BOT_BACKGROUND,
  DIGITAL_HUMAN_LOADING,
  DIGITAL_HUMAN_SETTING,
  HYGEN_PEER_CONNECTION,
  HYGEN_RENDER_ID,
  HYGEN_SESSION_ID,
  HYGEN_SESSION_INFO,
  INIT_HYGEN_SESSION_ERROR,
  SESSION_INITIALIZED_SUCCESSFULLY
} from "./constants";
import { DigitalHumanSetting, DigitalHumanState } from "./interfaces";

export default {
  [DIGITAL_HUMAN_LOADING]: (state: DigitalHumanState): boolean => state.loading,
  [BOT_BACKGROUND]: (state: DigitalHumanState): boolean => state.bot_background,
  [DIGITAL_HUMAN_SETTING]: (state: DigitalHumanState): DigitalHumanSetting =>
    state.bot_setting,
  [HYGEN_SESSION_ID]: (state: DigitalHumanState): string | null =>
    state.hygen_session_id,
  [HYGEN_SESSION_INFO]: (state: DigitalHumanState): any | null =>
    state.hygen_session_info,
  [HYGEN_RENDER_ID]: (state: DigitalHumanState): number =>
    state.hygen_render_id,
  [HYGEN_PEER_CONNECTION]: (
    state: DigitalHumanState
  ): RTCPeerConnection | null => state.hygen_peer_connection,
  [INIT_HYGEN_SESSION_ERROR]: (state: DigitalHumanState): string | null =>
    state.init_hygen_session_error,
  [SESSION_INITIALIZED_SUCCESSFULLY]: (state: DigitalHumanState): boolean =>
    state.session_initialized_successfully
};
