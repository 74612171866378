import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authentication_routes } from "@/router/authentication_routes";
import { onboarding_routes } from "@/router/onboarding_routes";
import store from "@/store";
import {
  GET_USER_DETAILS,
  NAVIGATE_USER_BASED_ON_ROLE,
  REHYDRATE_STATE,
  USER_STATE
} from "@/store/modules/auth/constants";
import { candidates_routes } from "@/router/candidate_routes";
import { recruiter_routes } from "@/router/recruiter_routes";
import { settings_routes } from "@/router/settings_routes";
import { talent_manager_routes } from "@/router/talent_manager_routes";
import { UserState } from "@/store/modules/auth/interfaces";
import { get_portal_formatted_name } from "@/utils/global";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "",
    redirect: {
      path: "auth"
    }
  },
  {
    path: "/book-demo",
    name: "book-demo",
    component: () => import("@/views/BookDemo.vue"),
    meta: {
      user_state: [
        UserState.UNAUTHENTICATED,
        UserState.CANDIDATE,
        UserState.RECRUITER,
        UserState.RECRUITER_ADMIN
      ]
    }
  },
  authentication_routes,
  onboarding_routes,
  candidates_routes,
  recruiter_routes,
  settings_routes,
  talent_manager_routes,
  {
    path: "/job/:id",
    name: "view-job-details",
    component: () => import("@/views/shared/ViewJobDetails.vue"),
    meta: {
      name: "nav-sidebar.jobs",
      icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
      user_state: [
        UserState.CANDIDATE,
        UserState.RECRUITER,
        UserState.RECRUITER_ADMIN
      ]
    }
  },
  {
    path: "/public/job/:key",
    name: "view-public-job-details",
    component: () => import("@/views/shared/ViewPublicJobDetails.vue"),
    meta: {
      user_state: [
        UserState.UNAUTHENTICATED,
        UserState.CANDIDATE,
        UserState.RECRUITER,
        UserState.RECRUITER_ADMIN
      ]
    }
  },
  {
    path: "/approval-pending",
    name: "approval-pending",
    component: () => import("@/views/shared/ApprovalPending.vue"),
    meta: {
      user_state: [UserState.ONBOARDING_COMPLETED]
    }
  },
  {
    path: "/error",
    component: () => import("../views/ErrorPage.vue"),
    meta: {
      user_state: [UserState.ROOT_ERROR]
    }
  },
  {
    path: "*",
    component: () => import("../views/NotFound.vue"),
    meta: {
      user_state: [
        UserState.UNAUTHENTICATED,
        UserState.EMAIL_VERIFICATION_PENDING,
        UserState.EMAIL_VERIFICATION_COMPLETED,
        UserState.ONBOARDING_PENDING,
        UserState.RECRUITER,
        UserState.RECRUITER_ADMIN,
        UserState.CANDIDATE
      ]
    }
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

const default_title = get_portal_formatted_name();

router.beforeEach(async (to, from, next) => {
  // If user press link in email to reset password
  if (
    to.meta &&
    to.meta.user_state.includes(UserState.RESET_PASSWORD) &&
    to.meta.link === true
  ) {
    store.commit(`auth/${USER_STATE}`, UserState.RESET_PASSWORD);
  }
  let user_state = store.getters[`auth/${USER_STATE}`];
  const user_exist = store.getters[`auth/${GET_USER_DETAILS}`];
  // If user not exist but user token exist in local storage
  // Rehydrating state & init user data
  if (
    !user_exist &&
    localStorage.getItem("token") &&
    user_state !== UserState.ROOT_ERROR &&
    user_state !== UserState.RESET_PASSWORD &&
    user_state !== UserState.RESET_PASSWORD_COMPLETED
  ) {
    // Init common data, user data
    await store.dispatch(`auth/${REHYDRATE_STATE}`);
    user_state = store.getters[`auth/${USER_STATE}`];
  }
  // Fetching user state from meta tag
  const meta_user_state = to.meta
    ? to.meta.user_state
    : [UserState.UNAUTHENTICATED];
  // If user state not include in next route meta tag
  // Cancel route
  if (!meta_user_state.includes(user_state)) {
    const not_allowed = [UserState.UNAUTHENTICATED, UserState.ROOT_ERROR];
    if (!user_exist && !not_allowed.includes(user_state)) {
      await store.dispatch(`auth/${NAVIGATE_USER_BASED_ON_ROLE}`);
      next();
    } else {
      // If user not logged in & user viewing internal page ==> Add redirect url
      if (
        user_state === UserState.UNAUTHENTICATED &&
        !to.query.redirect &&
        !to.fullPath.toLowerCase().includes("error")
      ) {
        next({
          name: "login",
          query: { redirect: to.fullPath }
        });
      } else {
        // If profile fetching is in progress => always navigate to profile loading page
        if (user_state === UserState.CANDIDATE_PROFILE_PENDING)
          next("/candidate/profile-loading");
        else next(false);
      }
    }
  } else {
    next();
  } // Navigate user
});

// Updating title
router.beforeResolve((to, from, next) => {
  let title = "";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (to.name) title = to.name.replace(/-/g, " ").toUpperCase();
  Vue.nextTick(() => {
    document.title = `${title} | ${default_title}` || default_title;
    const meta = document.querySelector('meta[property="og:title"]');
    if (meta)
      meta.setAttribute(
        "content",
        `${title} | ${default_title}` || default_title
      );
  });
  next();
});

export default router;
