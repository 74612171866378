import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#1C53F4",
        "primary-dark": "#2C32A9",
        "primary-light": "#33BFFF",
        "light-primary": "#F6F9FF",
        danger: "#FF4066",
        "danger-light": "#FF8473",
        success: "#3CE17E",
        "success-light": "#F4FCF5",
        info: "#2DFFF5",
        warning: "#FFB815",
        "warning-light": "#FEF8EE",
        pink: "#EE49FD",
        "pink-light": "#FEF4FF",
        "blue-light": "#EFFAFF",
        white: "#ffffff",
        dark: "#000000",
        "dark-darker": "#0F1322",
        grey: "#858D98",
        "light-bg": "#F1F5FE",
        background: "#FAFCFF",
        slider: "#3fd1c9",
        "text-dark": "#4B4E56",
        "purple-light": "#f6e3f6"
      }
    }
  }
});

export default vuetify;
