import {
  GET_JOB_BY_ID,
  GET_JOB_BY_KEY,
  ROLES,
  COMMON_LOADING,
  SET_UNIQUE_SECTORS,
  VIEW_JOB_STATE,
  RESET_COMMON_STATE,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_COUNT,
  NOTIFICATIONS,
  NOTIFICATION_ACTION_LOADING,
  SITE_DIRECTION,
  USER_INFO,
  SELECTED_USERS,
  RESET_USERS,
  APP_ASSESSMENTS,
  UPLOAD_FILE_CHUNK,
  COMPANY_SETTINGS
} from "@/store/modules/common/constants";
import {
  CommonState,
  SectorData,
  SiteDirections,
  ViewJobState
} from "@/store/modules/common/interfaces";
import { Roles } from "@/interfaces/data_objects/roles";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { NotificationsResponse } from "@/interfaces/responses/notifications";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { IAppAssessment } from "@/interfaces";

export default {
  [COMMON_LOADING]: (state: CommonState, payload: boolean): void => {
    state.loading = payload;
  },
  [SET_UNIQUE_SECTORS]: (state: CommonState, payload: SectorData[]): void => {
    state.sectors = payload;
  },
  [USER_INFO]: (state: CommonState, payload: SearchUserObject[]): void => {
    state.users_info = payload;
  },
  [SELECTED_USERS]: (state: CommonState, payload: SearchUserObject[]): void => {
    state.selected_users = payload;
  },
  [RESET_USERS]: (state: CommonState): void => {
    state.selected_users = [];
    state.users_info = [];
  },
  [ROLES]: (state: CommonState, payload: Roles[]): void => {
    state.roles = payload;
  },
  [GET_JOB_BY_ID]: (state: CommonState, payload: Jobs | null): void => {
    state.searched_job = payload;
  },
  /**
   * Vuex mutation to set the job data into the state.
   *
   * This mutation updates the `searched_job` property of the state with the payload.
   * If the payload is null, it indicates that the job could not be fetched or an error occurred,
   * and the `searched_job` state is set to null, effectively clearing any previously loaded job data.
   *
   * @param {CommonState} state - The current state object of the Vuex store module.
   * @param {Jobs | null} payload - The job data to be set in the state, or null to clear the state.
   *
   * Note: The `Jobs` type should match the expected structure of job data within the application.
   * It is assumed that `searched_job` is a state property intended to hold the job detail or null.
   */
  [GET_JOB_BY_KEY]: (state: CommonState, payload: Jobs | null): void => {
    state.searched_job = payload;
  },
  [VIEW_JOB_STATE]: (state: CommonState, payload: ViewJobState): void => {
    state.view_job_state = payload;
  },
  [RESET_COMMON_STATE]: (state: CommonState): void => {
    state.loading = false;
    state.searched_job = null;
    state.view_job_state = ViewJobState.DEFAULT;
    state.notifications = {
      loading: true,
      count: 0,
      notifications: [],
      action_loading: false
    };
    // state.site_direction = SiteDirections.LTR;
    state.upload_file_chunk_loading = false;
    state.company_setting = true;
  },
  [NOTIFICATIONS_LOADING]: (state: CommonState, payload: boolean): void => {
    state.notifications.loading = payload;
  },
  [NOTIFICATION_ACTION_LOADING]: (
    state: CommonState,
    payload: boolean
  ): void => {
    state.notifications.action_loading = payload;
  },
  [NOTIFICATIONS_COUNT]: (state: CommonState, payload: number): void => {
    state.notifications.count = payload;
  },
  [NOTIFICATIONS]: (
    state: CommonState,
    payload: NotificationsResponse[]
  ): void => {
    state.notifications.notifications = payload;
  },
  [SITE_DIRECTION]: (state: CommonState, payload: SiteDirections): void => {
    state.site_direction = payload;
  },
  [APP_ASSESSMENTS]: (
    state: CommonState,
    payload: IAppAssessment.Assessment[]
  ): void => {
    state.app_assessments = payload;
  },
  // Set Upload file chunk loading
  [UPLOAD_FILE_CHUNK]: (state: CommonState, payload: boolean): void => {
    state.upload_file_chunk_loading = payload;
  },
  [COMPANY_SETTINGS]: (state: CommonState, payload: boolean): void => {
    state.company_setting = payload;
  }
};
