export enum ResumeUploadsStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed"
}

export enum Portals {
  HCMS = "HCMS",
  COREHCS = "COREHCS",
  COREHCMS = "COREHCMS",
  DIGITAL_HUMAN = "DIGITAL_HUMAN",
  AI_LAWYER = "AI_LAWYER",
  AI_BOARD_MEMBER = "AI_BOARD_MEMBER"
}
