import axios, { AxiosResponse } from "axios";
import {
  ACTIVATE_PUBLISHED_JOB,
  FETCH_ASSESSMENT_LISTING,
  FETCH_FAVORITE_CANDIDATES,
  FETCH_INTERVIEW_HISTORY,
  FETCH_INTERVIEW_LISTING,
  FETCH_MEMBERS_JOBS_STATISTICS,
  FETCH_TOTAL_JOBS_COUNT,
  GET_JOBS_POSTED_BY_RECRUITER,
  GET_JOB_SHORT_URL,
  IS_MATCHING_CANDIDATES_EXIST,
  POKE_CANDIDATE,
  POST_JOB,
  RESUME_INTERVIEW,
  SAVING_UPDATED_JOB_DESCRIPTION,
  SOFT_DEL_JOB,
  START_INTERVIEW,
  UPDATE_INTERVIEW_STATUS,
  UPDATE_JOB_DETAILS,
  GET_SEARCH_CANDIDATES,
  ZAPPYHIRE_JOB_POST,
  ZAPPYHIRE_CREATE_CANDIDATE,
  ZAPPYHIRE_SSO,
  KNOWLEDGE_BASE_DOCUMENTS,
  ADVANCE_SEARCH,
  INTERVIEW_ACTION
} from "@/store/urls/recruiter";
import {
  FetchAssessmentListingPayload,
  GetJobsPostedPayload,
  GetJobShortUrlPayload,
  InterviewListingPayload,
  MembersJobsStatistics,
  PokeCandidatePayload,
  ResumeInterviewPayload,
  SaveUpdatedJobDescriptionPayload,
  SearchCandidatesPayload,
  StartInterviewPayload,
  UpdateFavoriteCandidatePayload,
  UpdateInterviewStatusPayload,
  UpdateJobPayload,
  KnowledgeBasePayload,
  AssessmentResultApiPayload,
  GetAssessmentResultApiPayload,
  InterviewActionPayload
} from "@/store/modules/recruiter/interfaces";
import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { ZappyhireCandidate } from "@/interfaces/data_objects/user";
import { APP_ASSESSMENTS } from "@/store/urls/common";
export const post_job = async (payload: FormData): Promise<AxiosResponse> => {
  return await axios.post(POST_JOB, payload);
};
// For update job
export const update_job = async (
  payload: UpdateJobPayload
): Promise<AxiosResponse> => {
  return await axios.post(UPDATE_JOB_DETAILS, payload);
};

export const get_jobs_posted_by_recruiter = async (
  payload: GetJobsPostedPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_JOBS_POSTED_BY_RECRUITER, payload);
};

/**
 * Sends a request to create a short URL for a specific job.
 *
 * This function makes an asynchronous POST request to a server endpoint
 * designated for creating short URLs for jobs. It submits a payload
 * containing the necessary job details and expects a response containing
 * the shortened URL.
 *
 * @param {GetJobShortUrlPayload} payload - An object containing the job details required to create the short URL.
 * @returns {Promise<AxiosResponse>} - A promise that resolves to the Axios response object containing the short URL data.
 *
 * Note: The function utilizes `GET_JOB_SHORT_URL` as the endpoint, which should be defined elsewhere in the application
 * to point to the correct URL creation service. The `GetJobShortUrlPayload` type should match the expected structure
 * required by the backend service for URL shortening.
 */
export const get_job_short_url = async (
  payload: GetJobShortUrlPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_JOB_SHORT_URL, payload);
};

/**
 * Function to softly delete job
 */
export const soft_delete_job = async (job_id: {
  job_id: number;
}): Promise<AxiosResponse> => {
  return await axios.post(SOFT_DEL_JOB, job_id);
};

/**
 * Function to activate published job
 */
export const activate_published_job = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.post(`${ACTIVATE_PUBLISHED_JOB}${job_id}`);
};

export const fetch_total_jobs_count = async (): Promise<AxiosResponse> => {
  return await axios.get(FETCH_TOTAL_JOBS_COUNT);
};

/**
 * Function to get member jobs stats
 */
export const fetch_members_jobs_stats = async (
  payload: MembersJobsStatistics
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_MEMBERS_JOBS_STATISTICS, payload);
};

/**
 * FUnction to get favorite candidate
 */
export const fetch_favorite_candidates = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_FAVORITE_CANDIDATES}${job_id}`);
};

/**
 * FUnction to update favorite candidate
 */
export const update_favorite_candidate = async (
  payload: UpdateFavoriteCandidatePayload
): Promise<AxiosResponse> => {
  return await axios.post(FETCH_FAVORITE_CANDIDATES, payload);
};

/**
 * Function to call backend API in order to poke candidate
 */
export const poke_candidate = async (
  payload: PokeCandidatePayload
): Promise<AxiosResponse> => {
  return await axios.post(POKE_CANDIDATE, payload);
};

// For saving updated job descriptions
export const save_updated_job_description = async (
  payload: SaveUpdatedJobDescriptionPayload
): Promise<AxiosResponse> => {
  return await axios.post(SAVING_UPDATED_JOB_DESCRIPTION, payload);
};

export const start_interview = async (
  payload: StartInterviewPayload
): Promise<AxiosResponse> => {
  return await axios.post(START_INTERVIEW, payload);
};

export const resume_interview = async (
  payload: ResumeInterviewPayload
): Promise<AxiosResponse> => {
  return await axios.post(RESUME_INTERVIEW, payload);
};

export const fetch_interview_listing = async (
  payload: InterviewListingPayload
): Promise<AxiosResponse> => {
  let query = "?";
  if (payload.page !== undefined) query += `page=${payload.page ?? 0}&`;
  if (payload.limit !== undefined) query += `limit=${payload.limit ?? 10}&`;
  if (payload.interviewed_by)
    query += `recruiter_id=${payload.interviewed_by}&`;
  if (payload.job_id) query += `job_id=${payload.job_id}&`;
  if (payload.candidate_id) query += `candidate_id=${payload.candidate_id}&`;
  if (payload.interview_id) query += `interview_id=${payload.interview_id}&`;
  return await axios.get(`${FETCH_INTERVIEW_LISTING}${query}`);
};

export const update_interview_status = async (
  payload: UpdateInterviewStatusPayload
): Promise<AxiosResponse> => {
  const { interview_id, ...rest } = payload;
  return await axios.patch(`${UPDATE_INTERVIEW_STATUS}${interview_id}`, rest);
};

export const fetch_interview_history = async (
  interview_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${FETCH_INTERVIEW_HISTORY}${interview_id}`);
};

export const is_matching_candidates_exist = async (
  job_id: number
): Promise<AxiosResponse> => {
  return await axios.get(`${IS_MATCHING_CANDIDATES_EXIST}${job_id}`);
};

export const fetch_assessment_listing = async (
  payload: FetchAssessmentListingPayload
): Promise<AxiosResponse> => {
  let query = `${FETCH_ASSESSMENT_LISTING}?page=${payload.page}&limit=${payload.limit}`;

  if (payload.date_picker_field?.length === 2) {
    const dates = JSON.stringify(payload.date_picker_field);
    query += `&dates=${dates}`;
  }

  if (payload?.selected_searched_user?.length > 0) {
    const users = JSON.stringify(payload.selected_searched_user);
    query += `&users=${users}`;
  }

  return await axios.get(query);
};

export const get_search_candidates = async (
  payload: SearchCandidatesPayload
): Promise<AxiosResponse> => {
  return await axios.post(GET_SEARCH_CANDIDATES, payload);
};

export const zappyhire_post_job = async (
  payload: ZappyhirePostjobPayload
): Promise<AxiosResponse> => {
  return await axios.post(ZAPPYHIRE_JOB_POST, payload);
};

export const zappyhire_create_candidate = async (
  payload: ZappyhireCandidate,
  job_id: number,
  candidate_id: number
): Promise<AxiosResponse> => {
  return await axios.post(
    `${ZAPPYHIRE_CREATE_CANDIDATE}${job_id}/${candidate_id}`,
    payload
  );
};

export const zappyhire_sso = async (): Promise<AxiosResponse> => {
  return axios.get(ZAPPYHIRE_SSO);
};

export const upload_knowledge_base = async (
  filename: string
): Promise<AxiosResponse> => {
  return axios.post(KNOWLEDGE_BASE_DOCUMENTS, { filename });
};

export const get_knowledge_base_document = async (
  payload: KnowledgeBasePayload
): Promise<AxiosResponse> => {
  return axios.get(
    `${KNOWLEDGE_BASE_DOCUMENTS}?page=${payload.page - 1}&limit=${
      payload.limit
    }`
  );
};

export const delete_knowledge_base_document = async (
  uri: string
): Promise<AxiosResponse> => {
  return axios.delete(`${KNOWLEDGE_BASE_DOCUMENTS}?uri=${uri}`);
};

export const advance_search = async (text: string): Promise<AxiosResponse> => {
  return await axios.post(ADVANCE_SEARCH, { text });
};

export const assessment_result = async (
  payload: AssessmentResultApiPayload
): Promise<AxiosResponse> => {
  const { assessment_id, user_id, ...rest } = payload;
  return await axios.post(
    `${APP_ASSESSMENTS}${assessment_id}/result/${user_id}`,
    rest
  );
};

export const get_assessment_result = async (
  payload: GetAssessmentResultApiPayload
): Promise<AxiosResponse> => {
  const { assessment_id, user_id } = payload;
  let query = `${APP_ASSESSMENTS}${assessment_id}/result/`;
  if (user_id) query += user_id;
  return await axios.get(query);
};

export const interview_action = async (
  payload: InterviewActionPayload
): Promise<AxiosResponse> => {
  return axios.post(INTERVIEW_ACTION, payload);
};
